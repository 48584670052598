img {
  max-width: 200px;
  max-height: 300px;
}
.formulaire {
  background: linear-gradient(90deg, #004aad, #cb6ce6);
  height: 100vh;
  margin-top: 0!important;
}

.titre-questionnaire {
  font-family: 'Bubblebody neue', cursive;
  font-size: 109px;
  color: #ffffff;
  min-height: 456px;
}

.conteneur-flex {
  background: linear-gradient(90deg, #004aad, #cb6ce6);
  width: 100%;
  max-width: 100%;
  display: flex;
}

.div-ratio, .div-image {
  display: flex;
  flex-direction: column;
  max-width: 50%;
}

.div-image img {
  display: flex;
}

.start-button {
  background-color: #f6b737;
  border-radius: 51px;

  &:hover {
    background-color: #f7bf50;
    transition: ease-in 300ms;
    cursor: pointer;
    transform: scale(1.05);
  }
}

.card {
  background-color: rgba(67, 56, 202, .2);
  border-radius: 0.5rem;
  padding: 2rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
}

.info-icon {
  color: #4F46E5;
  background-color: white;
  border-radius: 9999px;
  padding: 0.25rem;
}
